import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DialogContent, Alert, Box, Typography, Switch } from '@mui/material';
import { DialogActions, Button, Stack } from '@mui/material';
import { Warning as WarningIcon } from '@mui/icons-material';

import { DOCUMENT_UPLOADED } from '../common-demand';
import { useGetDocumentDataQuery } from '../services/documentData/documentDataApi';
import { resetGlobalDialogues } from '../redux/slices/globalDialogueSlice';
import { uploadFiles } from '../redux/thunks/demandData';
import { setToast } from '../redux/slices/globalToastSlice';
import DocumentUploader from '../DemandComponents/DocumentUploader';

const UploadDocumentsDialog = () => {
  const dispatch = useDispatch();

  const {
    documentId,
    data: { warningText, toggleText, toggleHelperText },
  } = useSelector((state) => state.GlobalDialogues.uploadDocumentsDialog);

  const { data: documentData = {} } = useGetDocumentDataQuery(
    { documentId, pollPdfStatus: false },
    { skip: !documentId, refetchOnMountOrArgChange: true, }
  );

  const { metadata } = documentData;
  const documentStatus = metadata?.documentStatus || '';

  const { documentUploaderList } = useSelector((state) => state.DemandDom);

  const { user } = useSelector((state) => state.User);

  const hideSwitch = documentStatus === DOCUMENT_UPLOADED; // Don't send for AI if the document is hanging in "new"/"uploaded" status

  const [sendForAiProcessing, setSendForAiProcessing] = useState(hideSwitch ? false : true);

  const hasFileErrors = documentUploaderList.some((file) => file.error);

  const handleCancel = () => {
    dispatch(resetGlobalDialogues());
  };

  const handleUpload = () => {
    dispatch(setToast({ isOpen: true, message: 'Uploading file(s)...', severity: 'info' }));
    dispatch(uploadFiles({ documentId, user, sendForAiProcessing, shouldFetchDocumentData: true }));
    dispatch(resetGlobalDialogues());
  };

  return (
    <>
      <DialogContent>
        {!hideSwitch && !sendForAiProcessing && (
          <Alert icon={<WarningIcon sx={styles.warningIcon} />} severity="warning" sx={styles.alert}>
            {warningText}
          </Alert>
        )}
        {!hideSwitch && (
          <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 2 }}>
            <Switch
              checked={sendForAiProcessing}
              onChange={(e) => setSendForAiProcessing(e.target.checked)}
              color="secondary"
            />
            <Stack>
              <Typography>{toggleText}</Typography>
              <Typography sx={styles.description}>{toggleHelperText}</Typography>
            </Stack>
          </Stack>
        )}

        <DocumentUploader heightOffset={500} />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCancel} color="secondary">
          Cancel
        </Button>
        <Button variant="contained" color="secondary" onClick={handleUpload} disabled={hasFileErrors || documentUploaderList.length == 0}>
          Upload
        </Button>
      </DialogActions>
    </>
  );
};

export default UploadDocumentsDialog;

const styles = {
  alert: {
    backgroundColor: '#fff3e0',
    marginBottom: '20px',
  },
  warningIcon: {
    marginRight: '8px',
  },
  description: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
};
