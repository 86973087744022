import { createAsyncThunk } from '@reduxjs/toolkit';
import awsExports from '../../aws-exports';
import { postDocumentFilesByIdsApi, postDocumentFilesApi, pushDataToS3, getUploadUrl } from '../../api';
import { setToast } from '../slices/globalToastSlice';
import { apiSlice } from '../../services/apiSlice';
import { pollPdfRegenerationStatus } from '../../services/pdf/thunks';
import { setPdfUrlPollingLoadingSkeleton } from '../../services/pdf/customSlice';
import { setIsDocumentsUploading } from '../slices/demandDomSlice';

const rootApiUrl = awsExports.ROOT_API_URL;

export const fetchInventoryDemandData = createAsyncThunk(
    'Inventory/fetchInventoryDemandDataStatus',
    async ({ requestBody, token }, { }) => {
        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: token
            },
            body: JSON.stringify(requestBody)
        };

        const response = await fetch(rootApiUrl + "/inventory", settings)
            .then((response) => response.json())

        return { response, isActive: requestBody.active_filter };
    },
);

export const fetchAuthorFormData = createAsyncThunk(
    'Author/fetchAuthorFormDataStatus',
    async ({ token, user, customerId = null, hasPermission = false }, { rejectWithValue }) => {
        if (hasPermission) {
            const settings = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    Authorization: token
                },
                body: JSON.stringify({ customerId: customerId })
            };

            const response = await fetch(
                rootApiUrl + "/authorFormData", settings
            ).then((response) => response.json())

            return { response, user };
        } else {
            return rejectWithValue({ error: 'User does not have permission to view this data' });
        }
    }
);

export const saveDocumentFilesBydId = createAsyncThunk(
    'DemandDom/saveDocumentFilesBydIdStatus',
    async ({ documentId, files, user }, { dispatch, rejectWithValue }) => {
        dispatch(setPdfUrlPollingLoadingSkeleton({ [documentId]: true }));
        const response = await postDocumentFilesByIdsApi(documentId, files, user)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(apiSlice.endpoints.getAllFilesByDocumentId.initiate(documentId, { forceRefetch: true }));
                    dispatch(pollPdfRegenerationStatus({ documentId }));
                    dispatch(setToast({ isOpen: true, severity: 'success', message: 'Files updated successfully' }));
                    return response.json();
                } else {
                    dispatch(setToast({ isOpen: true, severity: 'error', message: 'Error updating files' }));
                    return rejectWithValue(response.json());
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(setToast({ isOpen: true, severity: 'error', message: 'Error updating files' }));
                return rejectWithValue(error);
            });

    return response;
  }
);

export const uploadFiles = createAsyncThunk(
  'DemandDom/uploadFiles',
  async ({ documentId, user, sendForAiProcessing, shouldFetchDocumentData = true }, { dispatch, getState }) => {
    // loading state
    dispatch(setIsDocumentsUploading(true));
    dispatch(setPdfUrlPollingLoadingSkeleton({ [documentId]: true }));

    const state = getState();
    const { documentUploaderList } = state.DemandDom;

    // get upload urls and upload files to S3
    const uploadPromises = documentUploaderList.map(async (file) => {
      try {
        const uploadFields = await getUploadUrl(file.name, documentId, user);
        const success = await pushDataToS3(uploadFields, file);

        return {
          success: success,
          file: file,
        };
      } catch (error) {
        console.log(`${file.name}: Error with uploading file: ${error}`);
      }
    });

    const successfulFiles = [];
    const failedFiles = [];

    const promiseResults = await Promise.all(uploadPromises);

    // post uploaded files to document files api
    for (const promiseResult of promiseResults) {
      !promiseResult?.file?.error ? successfulFiles.push(promiseResult.file) : failedFiles.push(promiseResult.file);
    }

    if (successfulFiles.length > 0) {
      const uploaderListMappedtoFileBody = successfulFiles.map((file) => {
        return {
          fileName: file.name,
          detectedType: file.type,
          enableAiProcessing: sendForAiProcessing,
        };
      });

      try {
        await postDocumentFilesApi(documentId, { files: uploaderListMappedtoFileBody }, user);
      } catch (error) {
        console.log('error from postDocumentFilesApi', error);
      }
    }

    // reset loading state. PDF regen with optional refetch of document data
    dispatch(setIsDocumentsUploading(false));
    shouldFetchDocumentData
      ? dispatch(apiSlice.endpoints.getDocumentData?.initiate({ documentId, pollPdfStatus: true }, { forceRefetch: true }))
      : dispatch(pollPdfRegenerationStatus({ documentId }));

    return {
      successful: successfulFiles,
      failed: failedFiles,
    };
  }
);
