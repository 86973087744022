import React from 'react';
import CustomMuiDialogWrapper from '../CommonComponents/CustomMuiDialogWrapper';
import { Transition } from '../Transition';
import { useSelector, useDispatch } from 'react-redux';
import { resetGlobalDialogues } from '../redux/slices/globalDialogueSlice';
import UploadMedicals from './UploadMedicals';
import ConfirmDeleteFile from './ConfirmDeleteFile';
import ReimportMedicals from './ReimportMedicals';
import SavingDemand from './SavingDemand';
import AddICDCode from './AddICDCode';
import EditICDCode from './EditICDCode';
import EditMRIFinding from './EditMRIFinding';
import DocumentEditingConfirmation from './DocumentEditingConfirmation';
import EditNotesDialog from './EditNotesDialog';
import EditDocumentNames from './EditDocumentNames';
import AddMedicalTreatment from './AddMedicalTreatment';
import DeleteMedicalTreatment from './DeleteMedicalTreatment';
import SendForAIProcessingConfirmation from './SendForAIProcessingConfirmation';
import UploadDocumentsDialog from './UploadDocumentsDialog';

const GlobalDialogues = () => {
  const dispatch = useDispatch();

  const handleCloseDialog = () => dispatch(resetGlobalDialogues());

  const {
    uploadMedicals,
    confirmDeleteFile,
    reimportMedicals,
    savingDemand,
    addICDCode,
    editICDCode,
    editMRIFinding,
    documentEditingConfirmation,
    editDemandNotes,
    editDocumentNames,
    addMedicalTreatment,
    deleteMedicalTreatment,
    sendForAIProcessingConfirmation,
    uploadDocumentsDialog
  } = useSelector((state) => state.GlobalDialogues);

  return (
    <>
      {/* todo: map all of these (to avoid redundancy) and add wrapper, requires removing <Dialog> element from the display component */}
      {/* {maplist will need to include any custom props that are passed to the dialogues} */}
      {documentEditingConfirmation.open && <DocumentEditingConfirmation />}
      {uploadMedicals.open && <UploadMedicals />}
      {confirmDeleteFile.open && <ConfirmDeleteFile />}
      {reimportMedicals.open && <ReimportMedicals />}
      {savingDemand.open && <SavingDemand />}
      {addICDCode.open && <AddICDCode />}
      {editICDCode.open && <EditICDCode />}
      {editMRIFinding.open && <EditMRIFinding />}
      {editDemandNotes.open && <EditNotesDialog />}
      {sendForAIProcessingConfirmation.open && <SendForAIProcessingConfirmation />}

      <CustomMuiDialogWrapper
        open={editDocumentNames.open}
        onClose={handleCloseDialog}
        fullWidth={true}
        TransitionComponent={Transition}
        maxWidth='md'
      >
        {editDocumentNames.open && <EditDocumentNames />}
      </CustomMuiDialogWrapper>

      <CustomMuiDialogWrapper
        open={addMedicalTreatment.open}
        onClose={handleCloseDialog}
        fullWidth={true}
        TransitionComponent={Transition}
      >
        {addMedicalTreatment.open && <AddMedicalTreatment />}
      </CustomMuiDialogWrapper>

      <CustomMuiDialogWrapper
        open={deleteMedicalTreatment.open}
        onClose={handleCloseDialog}
        fullWidth={true}
        TransitionComponent={Transition}
      >
        <DeleteMedicalTreatment />
      </CustomMuiDialogWrapper>

      <CustomMuiDialogWrapper
        open={uploadDocumentsDialog.open}
        onClose={handleCloseDialog}
        fullWidth={true}
        maxWidth='md'
        TransitionComponent={Transition}
      >
        <UploadDocumentsDialog />
      </CustomMuiDialogWrapper>


    </>
  );
};

export default GlobalDialogues;
