import React, { useEffect } from 'react';
import { Button, Stack, Typography, IconButton, Box } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { CloudUploadOutlined, DeleteOutlined } from '@mui/icons-material';
import { setDocumentUploaderList } from '../redux/slices/demandDomSlice';
import { useDispatch, useSelector } from 'react-redux';

const CustomNoRowsOverlay = ({ handleInputChange }) => {

    return (
        <Stack
            alignItems={'center'}
            justifyContent={'center'}
            sx={{
                height: '100%',
                px: 4
            }}>
            <IconButton
                sx={{ height: '75px', width: '75px' }}
                component="label"
            >
                <CloudUploadOutlined sx={{ fontSize: 50 }} color='secondary' />
                <input
                    accept={".pdf,.jpg,.jpeg,.png,.tif,.tiff,.doc,.docx"}
                    type="file"
                    hidden
                    onChange={handleInputChange}
                    multiple
                />
            </IconButton>

            <Typography variant="h6" sx={{ mt: 1, textAlign: 'center' }}>Upload police reports, medical bills, and supporting documents</Typography>
            <Button
                startIcon={<CloudUploadOutlined />}
                variant="outlined"
                color="secondary"
                component="label"
                sx={{ mt: 2 }}
            >
                Upload documents
                <input
                    accept={".pdf,.jpg,.jpeg,.png,.tif,.tiff,.doc,.docx"}
                    type="file"
                    hidden
                    onChange={handleInputChange}
                    multiple
                />
            </Button>
            <Typography variant="caption" sx={{ mt: 2, textAlign: 'center', color: 'text.secondary' }}>File types: PDF, JPG/JPEG, PNG, TIF/TIFF, DOC/DOCX</Typography>
        </Stack>
    )
}

const DocumentUploader = ({ heightOffset = '128' }) => {

    useEffect(() => { //clear list on unmount
        return () => {
            dispatch(setDocumentUploaderList([]));
        }
    }, []);

    const dispatch = useDispatch();
    const { documentUploaderList } = useSelector((state) => state.DemandDom);

    const fileListWithId = documentUploaderList.map((file, index) => {
        return { ...file, id: file.name, name: file.name }
    })

    const handleInputChange = (e) => {
        const files = e.target.files;
        const newFileList = [...documentUploaderList, ...files];
        //iterate through new file list and if files have same name- remove the duplicate
        const uniqueFiles = newFileList.filter((file, index, self) =>
            index === self.findIndex((t) => (
                t.name === file.name
            ))
        )

        uniqueFiles.forEach((file) => {
            file.error = file.size > 157286400 ? 'File size cannot exceed 150MB' : null;
        })

        dispatch(setDocumentUploaderList(uniqueFiles));
    }

    return (
        <Box>
            <DataGridPro
                hideFooter
                rows={fileListWithId}
                slots={{
                    noRowsOverlay: CustomNoRowsOverlay
                }}
                slotProps={{
                    noRowsOverlay: {
                        handleInputChange: handleInputChange
                    }
                }}
                columns={[
                    {
                        field: 'name',
                        headerName: 'Name',
                        width: 450,
                        disableColumnMenu: true,
                        disableColumnFilter: true,
                        disableColumnSelector: true,
                        sortable: true,
                        renderCell: (params) => (
                            <Stack spacing={1}>
                                <Typography variant="body1">{params.row.name}</Typography>
                                {params.row.error && (
                                    <Typography variant="caption" color="error">{params.row.error}</Typography>
                                )}
                            </Stack>
                        ),
                    },
                    {
                        field: 'actions',
                        headerName: 'Actions',
                        width: 150,
                        disableColumnMenu: true,
                        disableColumnFilter: true,
                        disableColumnSelector: true,
                        sortable: false,
                        renderCell: (params) => (
                            <Stack direction="row" spacing={2}>
                                <IconButton
                                    onClick={() => {
                                        const newFileList = documentUploaderList.filter((file) => file.name !== params.row.name);
                                        dispatch(setDocumentUploaderList(newFileList));
                                    }}
                                >
                                    <DeleteOutlined
                                        variant="outlined"
                                    />
                                </IconButton>
                            </Stack>
                        ),
                    },
                ]}
                sx={{
                    height: `calc(100vh - ${heightOffset}px)`,
                }}
            />
        </Box>
    );
}

export default DocumentUploader;